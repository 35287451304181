.additional-menu {
  border-top: 1px solid #e4e4e4;
}

.additional-menu ul {
  list-style: none;
  margin: 0;
  padding: 0 0 10px !important;
}

.additional-menu li {
  padding: 13px 19px !important;
}

.additional-menu li:last-child {
  border-top: 1px solid #e4e4e4;
  padding-top: 20px;
}

.additional-menu .anticon {
  font-size: 24px;
  margin-right: 15px;
}

.additional-menu a.with-icon {
  display: flex;
  align-items: center;
  font: 600 16px/1em "Open Sans", sans-serif;
  text-transform: uppercase;
}

.additional-menu button {
  background: none;
  border: none;
  padding: 0;
  font: 600 16px/1em "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  transition: all ease-out 0.25s;
  cursor: pointer;
  text-transform: uppercase;
}

.additional-menu-modal .avatar,
.additional-menu-popover .avatar {
  padding: 20px 17px;
}

.additional-menu-modal .avatar .avatar-photo,
.additional-menu-popover .avatar .avatar-photo {
  margin-right: 12px;
}

.additional-menu-modal .avatar-data a,
.additional-menu-popover .avatar-data a {
  font: 600 16px/1em "Open Sans", sans-serif;
  text-transform: uppercase;
}

.additional-menu-button {
  width: 25px;
  height: 25px;
  min-width: 25px !important;
  min-height: 25px !important;
  padding: 4px 2px !important;
}

.additional-menu-button span {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}

.additional-menu-button span > * {
  height: 1px;
  width: 100%;
  background-color: #fff;
  display: block;
  position: absolute;
  transition: all ease-out 0.25s;
  left: 0;
}

.additional-menu-button span > *:first-child {
  top: 0;
}

.additional-menu-button span > *:nth-of-type(2) {
  top: 50%;
  transform: translate(0, -1px);
}

.additional-menu-button span > *:last-child {
  bottom: 0;
}

.additional-menu-button.open span > * {
  width: 106%;
}

.additional-menu-button.open span > *:first-child {
  transform: rotate(-45deg);
  transform-origin: right;
}

.additional-menu-button.open span > *:nth-of-type(2) {
  width: 0 !important;
  left: 50%;
}

.additional-menu-button.open span > *:last-child {
  transform: rotate(45deg);
  transform-origin: right;
}

.additional-menu-popover {
  position: fixed;
  top: 60px !important;
}

.additional-menu-popover .ant-popover-content {
  min-width: 280px;
}

.additional-menu-popover .ant-popover-inner-content {
  padding: 0;
}

.additional-menu-modal .ant-modal-body {
  /*max-height: calc(100vh - 51px);*/
  height: 100vh !important;
}

.main-bar-mobile .additional-menu-button span:nth-of-type(2) {
  transform: translate(0, 0px);
}
