.instruction .container {
  max-width: 1000px;
  margin: 70px auto 0;
  padding: 20px;
}

.instruction .instruction-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.instruction .menu-title {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.instruction .title {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.instruction .description {
  font-weight: 600;
}

.instruction .read-confirm {
  margin-bottom: 20px;
}

.instruction .styled-checkbox {
  margin-left: 10px;
}

.instruction .styled-checkbox .ant-checkbox-inner {
  width: 24px;
  height: 24px;
}

.instruction .styled-checkbox .ant-checkbox-inner::after {
  left: 7px;
  transform: rotate(45deg) scale(1.3) translate(-50%, -50%);
}

.instruction .read-confirm {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row-reverse;
}

.instruction .read-confirm strong {
  font-size: 16px;
  color: #b22222;
}

.instruction .description,
.instruction .author,
.instruction .attachments {
  font-size: 1rem;
  margin-bottom: 10px;
}

.instruction .attachments {
  margin-bottom: 0;
}

.instruction .attachment-header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 30px;
}

.instruction .attachment-list {
  padding: 0 10px;
}

.instruction .attachment-item {
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  background-color: #fafafa;
}

.instruction .title-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.instruction .title-holder .anticon-check-square {
  font-size: 25px;
  color: green;
}

.instruction .attachment-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.instruction .attachment-title a {
  color: black;
}

.instruction .attachment-title a:hover {
  color: #eb008b;
}

.instruction .attachment-description {
  font-size: 1rem;
  margin: 5px 0;
}

.instruction .attachment-info,
.instruction .attachment-date {
  font-size: 0.875rem;
  color: #888;
}

.instruction .attachment-iframe {
  width: 100%;
  height: 800px;
  border: none;
  margin-top: 10px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .instruction .container {
    padding: 10px;
    margin: 0 auto;
  }

  .instruction .title {
    font-size: 1.25rem;
  }

  .instruction .menu-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .instruction .description,
  .instruction .author,
  .instruction .attachments {
    font-size: 0.875rem;
  }

  .instruction .attachment-iframe {
    height: 450px;
  }

  .instruction .attachment-list {
    padding: 0;
  }

  .instruction .attachment-item {
    padding: 8px;
  }

  .instruction .attachment-item {
    list-style-type: none;
  }
}

@media (max-width: 480px) {
  .instruction .title {
    font-size: 1rem;
  }

  .instruction .menu-title {
    font-size: 1.25rem;
    margin-bottom: 15px;
  }

  .instruction .attachment-iframe {
    height: 500px;
  }
}
